import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, Collapse, Divider, FormControlLabel, FormGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleBackground from '../../Components/Background/Simple';
import SearchAutocomplete from '../../Components/UI/autoComplete';
import { getProfessions, getWallet, updateWallet } from '../../Config/api';
import { appendLoggedIn } from '../../Helper/helpers';
import CustomTextField from '../../Components/UI/Input/customTextField';

const disabledFields = ['state', 'district'];

export default function Profile() {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const addressFields = ['line1', 'district', 'state', 'pincode'];
  const [professions, setProfessions] = useState([{ name: 'Security Guard', value: 'Security Guard' },
    { name: 'Other', value: 'Other' }]);

  // useEffect(()=>{
  //   getProfessions()
  //       .then((x)=>{
  //         setProfessions(x);
  //       });
  // }, []);
  const [temp, setTemp] = useState('');
  const [info, setInfo] = useState(locationState?.preFilled ? locationState?.preFilled : {
    name: '',
    // employeeId: '',
    fatherName: '',
    profession: '',
    gender: '',
    dob: '',
    permanentAddress: {
      pincode: '',
      line1: '',
      state: '',
      district: '',
      country: 'India'
    },
    currentAddress: {
      pincode: '',
      line1: '',
      state: '',
      district: '',
      country: 'India'
    }
  });

  const [errors, setErrors] = useState({});

  const personalDetails = [
    // {
    //   name: 'name',
    //   label: 'Full Name',
    //   fieldType: 'textfield',
    //   type: 'text'
    // },
    // {
    //   name: 'employeeId',
    //   label: 'Employee ID',
    //   fieldType: 'textfield',
    //   type: 'text'
    // },
    // {
    //   name: 'fatherName',
    //   label: 'Guardian Name',
    //   fieldType: 'textfield',
    //   type: 'text'
    // },
    // {
    //   name: 'dob',
    //   label: 'Date of birth',
    //   fieldType: 'textfield',
    //   type: 'date'
    // },
    {
      name: 'profession',
      label: 'Profession',
      fieldType: 'dropdown',
      options: professions
    },
    // {
    //   name: 'gender',
    //   label: 'Gender',
    //   fieldType: 'dropdown',
    //   options: [{ name: 'Male', value: 'Male' }, { name: 'Female', value: 'Female' }, { name: 'Other', value: 'Other' }]
    // }
  ];


  const permanentAddress = [
    // {
    //   name: 'pincode',
    //   label: 'PIN Code',
    //   type: 'number',
    //   width: 12
    // },
    // {
    //   name: 'line1',
    //   label: 'Line 1',
    //   type: 'text',
    //   width: 12
    // },
    // {
    //   name: 'state',
    //   label: 'State',
    //   type: 'text',
    //   disabled: true,
    //   width: 6
    // },
    // {
    //   name: 'district',
    //   label: 'District',
    //   type: 'text',
    //   disabled: true,
    //   width: 6
    // }
  ];

  const currentAddress = [
    // {
    //   name: 'pincode',
    //   label: 'PIN Code',
    //   type: 'number',
    //   width: 12
    // },
    // {
    //   name: 'line1',
    //   label: 'Line 1',
    //   type: 'text',
    //   width: 12
    // },
    // {
    //   name: 'state',
    //   label: 'State',
    //   type: 'text',
    //   disabled: true,
    //   width: 6
    // },
    // {
    //   name: 'district',
    //   label: 'District',
    //   type: 'text',
    //   disabled: true,
    //   width: 6
    // }
  ];

  const [agree, setAgree] = useState(false);
  const [sameAsPermanentAddress, setsameAsPermanentAddress] = useState(true);

  const handleChange = async (name, value, type) => {
    console.log('handleChange:', name, value);
    setInfo((prev) => ({ ...prev, [name]: value }));
    // if (errors[name]) deleteFromErrors(errors, setErrors, name);
  };

  const handleSubmit = async () => {
    if (!info.profession) {
      setErrors((jj) => {
        const tempErr = { ...jj };
        tempErr['profession'] = 'Please select an option';
        return tempErr;
      });
      return null;
    }
    await updateWallet({ profession: info.profession }).then((res) => {
      console.info('here');
      if (locationState?.link) {
        navigate(appendLoggedIn(locationState.link), { state: { ...locationState.nextState } });
      } else {
        navigate('/' + '?loggedIn=true');
      }
    });
  };

  console.info(errors, 'Errors:', info);


  useEffect(() => {
    getWallet()
        .then((res) => {
          if (res) {
            const temp2 = {
            // name: res.data.name,
            // // email: {
            // //   value: res.data.email,
            // //   verified: true,
            // // },
            // mobile: res.data.mobile,
            // dob: res.data?.profileDetails?.dob,
            // fatherName: res.data?.profileDetails?.fatherName,
              profession: res.data?.profileDetails?.profession,
            // gender: res.data?.profileDetails?.gender,
            // currentAddress: Object.keys(res.data?.profileDetails?.currentAddress || {}).reduce((acc, item) => {
            //   if (addressFields.includes(item)) {
            //     acc[item] = res.data?.profileDetails?.currentAddress[item];
            //   }
            //   return acc;
            // }, {}),
            // permanentAddress: Object.keys(res.data?.profileDetails?.permanentAddress || {}).reduce((acc, item) => {
            //   if (addressFields.includes(item)) {
            //     acc[item] = res.data?.profileDetails?.permanentAddress[item];
            //   }
            //   return acc;
            // }, {}),
            // image: res.image
            };

            setInfo(temp2);
          }
        });
  }, []);

  return (
    <SimpleBackground
      sx={{
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        padding: '0 1em',
        margin: 'auto'
      }}
      title={locationState?.preFilled ? 'Profile' : 'Setup Your Profile'}
      disableBack={locationState?.disableBack}>
      <Box sx={{ width: '100%', display: 'flex' }} mt={2} mb={1}>
        <Typography
          variant="h6" sx={{
            margin: 0,
            fontWeight: 600,
            textAlign: 'left',
            fontSize: '1.3rem'
          }}>
          Personal Details
        </Typography>
      </Box>

      <Box sx={{ width: '100%' }}>
        <>
          <SearchAutocomplete
            id={`profession`}
            sx={{ width: '100%', margin: '0em 0 0 0' }}
            initialText={info?.profession || 'Select a profession'}
            label={`Profession`}
            name={`Profession`}
            selectedKey={info?.profession}
            updateSelectedKey={(event) => {
              console.log('updateSelectedKey:', event);
              setErrors((jj) => {
                const tempErr = { ...jj };
                delete tempErr?.[info.profession];
                return tempErr;
              });
              setTemp(event?.value);
              console.log('handleChange:2', event);
              if (event?.value !== 'Other') {
                handleChange('profession', event?.value || '', 'info');
              } else {
                handleChange('profession', '', 'info');
              }
            }}
            apiTrigger={async (searchThis = '', newSeedValue = 0) => {
              try {
                const response = await getProfessions(newSeedValue, 50, searchThis);
                // const response = {
                //   data: {
                //     'code': 0,
                //     'message': 'Success',
                //     'data': {
                //       'total': '1',
                //       'list': [
                //         {
                //           '_id': '64e854cbfb360881c894a0d1',
                //           'name': 'University of Petroleum and Energy Studies',
                //           'onGridData': {
                //             'name': 'University of Petroleum and Energy Studies',
                //             'pricing': {
                //               'price': '10000',
                //               'urgent': false,
                //               'periodStart': 0,
                //               'periodEnd': 1644940800,
                //               'currency': 'INR'
                //             }
                //           },
                //           'type': 'university',
                //           'state': 'Uttarakhand',
                //           'ownership': 'private',
                //           'domains': []
                //         }
                //       ]
                //     }
                //   }
                // };
                const x = ({
                  ...response,
                  list: response?.list?.reduce((acc, item, i) => {
                    acc.push({ name: item.name, value: item.name });
                    return acc;
                  }, []),
                });
                console.log('response:', x);
                return x;
              } catch (e) {
                console.log(e);
                return { list: [{ name: 'Other', value: 'Other' }], total: 0 };
              }
            }}
            getName={(option) => {
              return ({ primary: option.name });
            }
            }
            error={Boolean(errors?.profession || false) || false}
            other={{ name: 'Other', value: 'Other' }}
          />
          <Collapse
            in={temp === 'Other'} sx={{
              'width': '100%',
              '& .MuiCollapse-wrapperInner': {
                display: 'flex !important',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              },
              'margin': '0em 0 1em 0'
            }}>
            <CustomTextField
              autoFocus
              variant={'outlined'}
              type={'text'}
              sx={{
                'margin': '0em auto 0', 'width': '90%',
                '& .MuiInputBase-root': {
                  marginBottom: 0
                }
              }}
              inputProps={{
                style: { paddingRight: '16px', margin: '0em auto 0' },
              }}
              label={`Enter other Profession`}
              value={info?.profession || ''}
              onChange={(event) => {
                event.target.value = event.target.value.trimStart();
                setErrors((jj) => {
                  const tempErr = { ...jj };
                  delete tempErr?.['profession'];
                  return tempErr;
                });
                handleChange('profession', event.target.value, 'info');
              }}
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors['profession'] || false) || false}
              helperText={errors['profession']}
            />
          </Collapse>


        </>
      </Box>

      <Divider sx={{ width: '100%', marginTop: '1.5rem', color: '#DADADA' }} />


      <Box sx={{ width: '100%' }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox
              checked={agree} onChange={() => {
                setAgree(!agree);
              }} />} label=
              {
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ fontSize: '1.2rem', fontFamily: 'Lato', marginRight: '.2rem', color: '#6D6D6D' }}>
                  I agree to the
                  </Typography>
                  <Typography
                    sx={{ fontSize: '1.2rem', fontFamily: 'Lato', color: '#044DA1' }}
                    onClick={() => {
                      return process.env.REACT_APP_WES_AUTH ?
                      window.open('https://storage.googleapis.com/indisi-wallet-app/NSDC%20Trust%20Terms%20of%20Use.pdf', '_blank') :
                      navigate('/');
                    }}>
                  Terms and Conditions
                  </Typography>
                </Box>
              }
          />
        </FormGroup>
      </Box >

      <LoadingButton
        // loading={loadingYes}
        disabled={!agree}
        variant="contained"
        sx={{ fontWeight: 600, margin: '1em 0' }}
        onClick={() => {
          handleSubmit();
        }}>
        Continue
      </LoadingButton>

    </SimpleBackground >
  );
}
